import { h } from 'preact'

import Form from 'components/Form'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import AvatarInput from 'components/AvatarInput'
import CropImageInputFormRow from 'components/CropImageInputFormRow'
import './index.sass'
import WYSIWYGInput from 'components/WYSIWYGInput'
import RichMediaOrWYSIWYGInput from 'components/RichMediaOrWYSIWYGInput'

export default function PublicProfileFormFields({ form, editForm, submitText, onUploading }) {
  return <div className="PublicProfileFormFields">
    <CropImageInputFormRow
      cropImageInput={
        form.bindInput({
          valueProp: 'avatar',
          input: <AvatarInput {...{ onUploading }}/>
        })
      }
    >
      {editForm ||
        form.textItem({
          valueProp: 'username',
          label: 'USERNAME',
          name: 'username',
          autocomplete: 'username',
          maxlength: 35,
          pattern: '[A-Za-z0-9_-]+',
          required: true,
        })
      }
      { editForm &&
        <Form.Row>
          {form.textItem({
            valueProp: 'city',
            label: 'CITY',
            name: 'city',
            autocomplete: 'address-level1 locality city',
          })}
          {form.textItem({
            valueProp: 'province',
            label: 'STATE / PROVINCE',
            name: 'province',
            autocomplete: 'administrative-area state province region',
          })}
        </Form.Row>
      }
    </CropImageInputFormRow>
    <Form.Row>
      {form.item({
        valueProp: 'country',
        label: 'COUNTRY',
        input: <CountryDropdownPicker />
      })}
      {form.textItem({
        valueProp: 'postalCode',
        label: isDataYogi ? 'POSTCODE / ZIPCODE' : 'ZIP CODE',
        name: 'postal-code',
        autocomplete: 'postal-code',
      })}
    </Form.Row>
    { editForm && !isDataYogi &&
      <Form.Row>
        {form.bindInput({
          valueProp: 'bio',
          name: 'bio',
          label: 'BIO',
          bindTo: 'onInput',
          input: <WYSIWYGInput/>
        })}
      </Form.Row>
    }
    <Form.ButtonRow className="PublicProfileFormFields-buttonRow">
      {editForm && form.resetButton({ value: 'Cancel' })}
      {form.submitButton({
        value: `${submitText.submit}`,
        submittingValue: `${submitText.submitting}`,
        disabled: form.isPristine,
      })}
    </Form.ButtonRow>
  </div>
}
