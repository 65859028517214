import JLINCAPI from 'lib/JLINCAPI'

class AliceAPI extends JLINCAPI {

  sendHelpRequest(helpRequest) {
    return this.postJSON('/help_request', helpRequest)
  }

  getHelpAndFeedbackRequests() {
    return this.getJSON('/help_and_feedback_requests')
  }

  verifyExternalIdentity({ verificationCode }) {
    return this.postJSON('/external_identities/verify', { verificationCode })
  }

  getPublicOrganizations() {
    return this.getJSON('/public_organizations')
  }

  getOrganizationApikeyAvailability(organizationApikey) {
    return this.getJSON(`/organizations/${organizationApikey}/availability`)
  }

  createOrganization({ organization, organizationMembershipInviteToken }) {
    return this.postJSON(`/organizations`, { organization, organizationMembershipInviteToken })
  }

  updateOrganization({ organizationApikey, changes }) {
    return this.postJSON(`/organizations/${organizationApikey}`, changes)
  }

  getOrganization({ organizationApikey }) {
    return this.getJSON(`/organizations/${organizationApikey}`)
  }

  // we have to batch these requests incase we go over the 2083 url character limit
  getOrganizations({ organizationApikeys }) {
    const apikeys = Array.from(new Set(organizationApikeys))
    const batches = ['']
    apikeys.forEach(apikey => {
      if (batches[0] === '') {
        batches[0] = apikey
        return
      }
      const addition = batches[0] + '~' + apikey
      if (addition.length <= 2000) batches[0] = addition
      else batches.unshift(apikey)
    })
    return Promise.all(batches.map(apikeys =>
      this.getJSON(`/organizations`, { apikeys })
    )).then(responses => {
      return responses.reduce((a, b) => {
        const merged = {}
        'organizations memberships publicProfiles'.split(' ').forEach(key => {
          merged[key] = [...a[key], ...b[key]]
        })
        return merged
      })
    })
  }

  getOrganizationAccountdata({ organizationApikey }) {
    return this.getJSON(`/organizations/accountData/${organizationApikey}`)
      .then(({ organizationAccountData }) => organizationAccountData)
  }

  updateOrganizationsAccountData({ organizationApikeys, changes }) {
    return this.postJSON(`/organizations/accountData`, { organizationApikeys, changes })
  }

  getDefaultAccountdata() {
    return this.getJSON(`/default-account-data`)
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updateDefaultAccountData({ defaultAccountData }) {
    return this.postJSON(`/default-account-data`, { defaultAccountData })
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updatePreferences({ preferences }) {
    return this.postJSON(`/preferences`, { preferences })
  }

  getExternalIdentities() {
    return this.getJSON(`/external_identities`)
  }

  addExternalIdentity({ type, value }) {
    return this.postJSON(`/external_identities`, { type, value })
  }

  addAuthenticatorApp() {
    return this.postJSON(`/authenticator_app/add`)
  }

  getAuthenticatorApp() {
    return this.postJSON(`/authenticator_app/get`)
  }

  verifyAuthenticatorApp({ code }) {
    return this.postJSON(`/authenticator_app/verify`, { code })
  }

  deleteAuthenticatorApp() {
    return this.postJSON(`/authenticator_app/delete`)
  }

  webauthRegistration() {
    return this.postJSON('/webauthn/register_start')
  }

  addPasskey({ authRes }) {
    return this.postJSON(`/webauthn/register_finish`, { authRes })
  }

  generateAuthenticationOptions() {
    return this.postJSON('/webauthn/login_start')
  }

  getPasskeys() {
    return this.postJSON(`/webauthn/list`)
  }

  deletePasskey({ credentialID }) {
    return this.postJSON(`/webauthn/delete`, { credentialID })
  }

  getRequire2FA() {
    return this.postJSON(`/require_2fa/`, {})
  }

  updateRequire2FA(value) {
    return this.postJSON(`/require_2fa/update`, { value })
  }

  getRecoveryCodes() {
    return this.postJSON(`/recovery_codes/get`, {})
  }

  generateRecoveryCodes() {
    return this.postJSON(`/recovery_codes/generate`, {})
  }

  deleteRecoveryCodes() {
    return this.postJSON(`/recovery_codes/delete`, {})
  }

  resendExternalIdentityVerificationCode({ value }) {
    return this.postJSON(`/external_identities/resend_verification_code`, { value })
  }

  deleteExternalIdentity({ value }) {
    return this.postJSON(`/external_identities/delete`, { value })
  }

  createBuyingInterest({ buyingInterest }) {
    return this.postJSON(`/buying_interest/create`, { buyingInterest })
  }

  getBuyingInterests() {
    return this.getJSON(`/buying_interest`)
  }

  deleteBuyingInterest({ buyingInterestUid }) {
    return this.postJSON(`/buying_interest/delete`, { buyingInterestUid })
  }

  getSisaEventsForOrganization({ organizationApikey }) {
    return this.getJSON(`/organizations/sisa_events/${organizationApikey}`)
      .then(({ organizationSisaEvents }) => organizationSisaEvents)
  }

  getOrganizationByApiKey({ organizationApikey }) {
    return this.getJSON(`/organization/${organizationApikey}`)
      .then(({ organization }) => organization)
  }

  requestOrganizationDelete(organizationApikey) {
    return this.postJSON(`/request_organization_delete/${organizationApikey}`)
  }

  getFeedPosts({ username, organizationApikey, published, order, before, pageSize, topicUid, includeTopicPosts }){
    published = published ? '1' : undefined
    if (before instanceof Date) before = before.toISOString()
    return this.getJSON(`/feed/posts`, {
      username, organizationApikey, published, order, before, pageSize, topicUid, includeTopicPosts
    })
  }

  getFeedPost({ feedPostUid }) {
    return this.getJSON(`/feed/posts/${feedPostUid}`)
  }

  getFeedPostProvenance({ feedPostUid }) {
    return this.getJSON(`/feed/posts/${feedPostUid}/provenance`)
  }

  createFeedPost({ post }) {
    return this.postJSON(`/feed/posts`, { post })
  }

  updateFeedPost({ feedPostUid, changes }) {
    return this.postJSON(`/feed/posts/${feedPostUid}`, { changes })
  }

  repostFeedPost({
    feedPostUid,
    posterOrganizationApikey,
    feedOrganizationApikey,
    visibleTo,
    maxVisibleTo,
  }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/repost`, {
      posterOrganizationApikey,
      feedOrganizationApikey,
      visibleTo,
      maxVisibleTo,
    })
  }

  publishFeedPost({ feedPostUid, visibleTo, maxVisibleTo }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/publish`, { visibleTo, maxVisibleTo })
  }

  getFeedPostsDeletingReasons() {
    return this.getJSON(`/deleting-reasons`)
  }

  deleteFeedPost({ feedPostUid, comment, reason, sendNotification }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/delete`, { feedPostUid, comment, reason, sendNotification })
  }

  requestDeleteFeedPostEntirely({ feedPostUid, selectedOption, reason }) {

    return this.postJSON(`/request_feed_post_delete_entirely/${feedPostUid}`, {
      feedPostUid,
      selectedOption,
      reason,
    })
  }

  getAllDeletionRequests(organizationApikey) {
    return this.getJSON(`/deletion_requests/${organizationApikey}`)
  }

  hideFeedPostFromMe({ feedPostUid }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/hide-from-me`)
  }

  hideFeedPost({ feedPostUid }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/hide`, { feedPostUid })
  }

  voteOnFeedPost({ feedPostUid, vote }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/vote`, { vote })
  }

  pinFeedPost({ feedPostUid }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/pin`)
  }

  unpinFeedPost({ feedPostUid }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/unpin`)
  }

  getPinnedPostsForOrganization({ organizationApikey }) {
    return this.getJSON(`/organizations/${organizationApikey}/feed/posts/pinned`)
  }

  createFeedPostComment({ feedPostUid, comment }) {
    return this.postJSON(`/feed/posts/${feedPostUid}/comments`, { comment })
  }

  deleteFeedPostComment({ commentUid }) {
    return this.postJSON(`/feed/comments/${commentUid}/delete`)
  }

  getFeedPostComments({ feedPostUid, before }) {
    return this.getJSON(`/feed/posts/${feedPostUid}/comments`, { before })
  }

  getAssetUploadToken() {
    return this.postJSON(`/create_asset_upload_token`)
      .then(({ assetUploadToken }) => assetUploadToken)
  }

  getProductsAndServices() {
    return this.getJSON(`/products_and_services`)
  }

  createProductOrService({ productOrService }) {
    return this.postJSON(`/products_and_services`, { productOrService })
  }

  getProductOrService({ uid }) {
    return this.getJSON(`/products_and_services/${uid}`)
  }

  deleteProductOrService({ uid }) {
    return this.postJSON(`/products_and_services/${uid}/delete`)
  }

  getPublicProfile() {
    return this.getJSON('/public_profile')
  }

  updatePublicProfile({ publicProfile }) {
    return this.postJSON(`/public_profile`, { publicProfile })
  }

  getPublicProfiles({ dids, usernames }) {
    return this.getJSON('/public_profiles', { 'dids[]': dids, 'usernames[]': usernames })
  }

  async createProfileBlock(profileBlockData) {
    return await this.postJSON(`/user_info/profile_blocks`, { profileBlockData })
  }


  async createProfileBio(bio) {
    return await this.postJSON(`/user_info/profile_blocks/bio`, { bio })
  }

  async createProfileHubMembership(hubMembership) {
    return await this.postJSON(`/user_info/profile_blocks/hub_membership`, { hubMembership })
  }


  async getProfileBlocks(did) {
    return await this.getJSON(`/user_info/${did}/profile_blocks`)
  }


  async updateProfileBlocksOrder(displayOrder) {
    return await this.postJSON(`/user_info/profile_blocks/order`, { displayOrder })
  }

  async updateProfileBlockWidth(id, width) {
    return await this.postJSON(`/user_info/profile_blocks/${id}/width`, { width })
  }

  async deleteProfileBlock(id) {
    return await this.postJSON(`/user_info/profile_blocks/${id}/delete`)
  }

  async createProfileContactInfo(contactInfo) {
    return await this.postJSON(`/user_info/contact_info`, { contactInfo })
  }


  async getProfileContactInfo(did) {
    return await this.getJSON(`/user_info/${did}/contact_info`)
  }

  async updateProfileContactInfo(id, contactInfo) {
    return await this.postJSON(`/user_info/contact_info/${id}`, { contactInfo })
  }

  async createProfileGeographicLocation(geographicLocation) {
    return await this.postJSON(`/user_info/geographic_location`, { geographicLocation })
  }

  async getProfileGeographicLocation(did) {
    return await this.getJSON(`/user_info/${did}/geographic_location/`)
  }


  async getCountries() {
    return await this.getJSON('/countries')
  }
  async getLocation({ country_code, state_code, method  }) {

    return await this.getJSON(`/locations/${country_code}`, { country_code, state_code, method})
  }

  async updateProfileGeographicLocation(locationId, changes) {
    return await this.postJSON(`/user_info/geographic_location/${locationId}/update`, { changes })
  }

  async deleteProfileGeographicLocation(locationId) {
    return await this.postJSON(`/user_info/geographic_location/${locationId}/delete`)
  }

  async createProfileEducation(education) {
    return await this.postJSON(`/user_info/education`, { education })
  }

  async getProfileEducation(did) {
    return await this.getJSON(`/user_info/${did}/education/`)
  }


  async updateProfileEducation(educationId, changes) {
    return await this.postJSON(`/user_info/education/${educationId}/update`, { changes })
  }

  async deleteProfileEducation(educationId) {
    return await this.postJSON(`/user_info/education/${educationId}/delete`)
  }


  async createProfileCareer(career) {
    return await this.postJSON(`/user_info/career`, { career })
  }

  async getProfileCareer(did) {
    return await this.getJSON(`/user_info/${did}/career`)
  }

  async updateProfileCareer(careerId, changes) {
    return await this.postJSON(`/user_info/career/${careerId}/update`, { changes })
  }

  async deleteProfileCareer(careerId) {
    return await this.postJSON(`/user_info/career/${careerId}/delete`)
  }

  async deleteProfileCustomField(customFieldKey) {
    return await this.postJSON(`/user_info/contact_info/custom_fields/${customFieldKey}/delete`)
  }

  async updateDisplayOrder(entityType, entityId, displayOrder) {
    return await this.postJSON(`/entities/${entityType}/${entityId}/display-order`, { displayOrder })
  }

  async createProfileFavoriteContent(favorite) {
    return await this.postJSON(`/favorites`, { favorite })
  }

  async getFavorites(did) {
    return await this.getJSON(`/user_info/${did}/favorites`)
  }

  async updateFavorite(id, changes) {
    return await this.postJSON(`/favorites/${id}`, { changes })
  }

  async deleteFavorite(id) {
    return await this.postJSON(`/favorites/${id}/delete`)
  }

  async createProfileMyContentItem(myContentItem) {
    return await this.postJSON(`/user_info/my_content_block`, { myContentItem })
  }

  async getMyContentBlock(did) {
    return await this.getJSON(`/user_info/${did}/my_content_block`)
  }

  async updateMyContentItem(id, changes) {
    return await this.postJSON(`/user_info/my_content_block/${id}`, { changes })
  }

  async deleteMyContentItem(id) {
    return await this.postJSON(`/user_info/my_content_block/${id}/delete`)
  }

  async createProfileSlideshow(slideshow) {
    return await this.postJSON(`/user_info/slideshow`, { slideshow })
  }

  async getProfileSlideshow(did) {
    return await this.getJSON(`/user_info/${did}/slideshow`)
  }

  async updateSlideshow(id, changes) {
    return await this.postJSON(`/user_info/slideshow/${id}`, { changes })
  }

  async deleteSlideshow(id) {
    return await this.postJSON(`/user_info/slideshow/${id}/delete`)
  }

  async createProfileGallery(gallery) {
    return await this.postJSON(`/user_info/gallery`, { gallery })
  }

  async getProfileGallery(did) {
    return await this.getJSON(`/user_info/${did}/gallery`)
  }

  async updateGallery(id, changes) {
    return await this.postJSON(`/user_info/gallery/${id}`, { changes })
  }

  async deleteGallery(id) {
    return await this.postJSON(`/user_info/gallery/${id}/delete`)
  }

  async getOrganizationMemberships() {
    return (await this.getJSON('/organizations/memberships')).organizationMemberships
  }

  createOrganizationMembership({ organizationApikey, inviteToken, memberUserDid }) {
    return this.postJSON(
      `/organizations/${organizationApikey}/memberships`,
      { inviteToken, memberUserDid },
    )
  }

  updateOrganizationMembership({ organizationApikey, organiztionMembershipUid, changes }) {
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${organiztionMembershipUid}`, changes)
  }

  deleteOrganizationMembership({ organizationApikey, membershipUid }) {
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${membershipUid}/delete`)
  }

  resolveOrganizationMembershipRequest({ organizationApikey, membershipRequestUid, accept }) {
    return this.postJSON(
      `/organizations/${organizationApikey}/membership_requests/${membershipRequestUid}/resolve`,
      { accept },
    )
  }

  createOrganizationMembershipInvite({ organizationApikey, maxUsage, validUntil }) {
    return this.postJSON(`/organizations/${organizationApikey}/membership_invites`, { maxUsage, validUntil })
  }
  createOrganizationMembershipInvites({ organizationApikey, memberUserDids, message}){
    return this.postJSON(`/organizations/${organizationApikey}/memberships_invites`, { memberUserDids, message })
  }

  getOrganizationMembershipInvite({ token }) {
    return this.getJSON(`/organizations/membership_invites/${token}`)
  }

  getOrganizationMembershipInvites({ organizationApikey }) {
    return this.getJSON(`/organizations/${organizationApikey}/membership_invites`)
  }

  async getFormerMemberships(organizationApikey) {
    return (await this.getJSON(`/organizations/${organizationApikey}/former_memberships`)).organizationFormerMemberships
  }

  getMembershipsForPublicProfile({ publicProfileDid }) {
    return this.getJSON(`/public_profile/${publicProfileDid}/memberships`)
  }

  createCovidReport(covidReport) {
    return this.postJSON(`/covid_report`, { covidReport })
  }

  getCovidReports() {
    return this.getJSON(`/covid_reports`)
  }

  getNotifications({ before }) {
    return this.getJSON('/notifications', { before })
  }

  getNotificationSettings() {
    return this.getJSON('/notifications/settings')
  }

  updateNotificationSettings(notificationSettings) {
    return this.postJSON('/notifications/settings', { notificationSettings })
  }


  markNotificationsAsRead({ uids }) {
    return this.postJSON(`/notifications/seen`, { uids })
  }

  subscribeToOrganizationFeed({ organizationApikey, subscriberOrganizationApikey, topicUid, subscriberTopicUid
  }) {
    return this.postJSON(`/organizations/${organizationApikey}/feed/subscriptions`, {
      subscriberOrganizationApikey,
      topicUid,
      subscriberTopicUid
    })
  }

  unsubscribeFromOrganizationFeed({
    organizationFeedSubscriptionUid,
    subscriberOrganizationApikey
  }) {
    return this.postJSON(
      `/organizations/feed_subscriptions/${organizationFeedSubscriptionUid}/delete`,
      {
        subscriberOrganizationApikey
      },
    )
  }

  getMyOrganizationFeedSubscriptions() {
    return this.getJSON(`/organizations/feed_subscriptions`)
  }

  getOrganizationFeedSubscriptions(organizationApikey) {
    return this.getJSON(`/organizations/${organizationApikey}/feed/subscriptions`)
  }

  search(searchQuery, page) {
    return this.getJSON('/search', { q: searchQuery, page })
  }

  searchForOrganizations(searchQuery, page) {
    return this.getJSON('/organizations/search', { q: searchQuery, page })
  }

  inviteUserToOrganization({ organizationApikey, publicProfileDid }) {
    return this.postJSON(`/organizations/${organizationApikey}/invite/${publicProfileDid}`, {})
  }

  getUrlPreview(url) {
    return this.getJSON(`/url-preview`, { url })
  }
  getSuggestedHubs(profileDids) {
    return this.postJSON(`/suggestions`, { profileDids })
  }

  async getChatSynopsis() {
    return (await this.getJSON('/chat')).chatSynopsis
  }

  async createChatChannel(chatChannel) {
    return (await this.postJSON(`/chat`, chatChannel)).chatChannel
  }

  leaveChatChannel(chatChannelUid) {
    return this.postJSON(`/chat/${chatChannelUid}/leave`)
  }

  async deleteChatChannel(chatChannelUid) {
    return await this.postJSON(`/chat/${chatChannelUid}/delete`)
  }

  async addMembersToChatChannel(chatChannelUid, memberUserDids) {
    return await this.postJSON(`/chat/${chatChannelUid}/add_members`, { memberUserDids })
  }

  async removeMembersFromChatChannel(channelUid, memberUserDids) {
    return await this.postJSON(`/chat/${channelUid}/remove_members`, { memberUserDids })
  }

  async assignChatChannelAdminRole(chatChannelUid, adminUserDid) {
    return await this.postJSON(`/chat/${chatChannelUid}/assign_admin`, { adminUserDid })
  }

  async removeChatChannelAdminRole(chatChannelUid, adminUserDid) {
    return await this.postJSON(`/chat/${chatChannelUid}/remove_admin`, { adminUserDid })
  }

  async addChatChannelDetails(chatChannelUid, chatName, imageUrl, description) {
    return await this.postJSON(`/chat/${chatChannelUid}/channel_details`, {
      chatName,
      imageUrl,
      description
    })
  }

  async getChatChannelMembershipDetails(chatChannelUid) {
    return await this.getJSON(`/chat/${chatChannelUid}/memberships`)
  }

  async getChatMessages(chatChannelUid) {
    return (await this.getJSON(`/chat/${chatChannelUid}`)).chatMessages
  }

  async createChatMessage(chatChannelUid, message, caption, urlPreviewId) {
    return (await this.postJSON(`/chat/${chatChannelUid}`, { message, urlPreviewId, caption })).chatMessage
  }

  async replyToChatMessage(chatChannelUid, messageUid, replyContent, urlPreviewId, sendToMainChat) {
    const response = await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reply`,
      { replyContent, urlPreviewId, sendToMainChat })
    if (!response || !response.chatMessage) {
      console.error("Unexpected response structure from server:", response)
    }
    return response.chatMessage
  }
  async getMutedUsers({ organizationDid }) {
    try {
      const response = await this.getJSON(`/muted_users/${organizationDid}`)
      return response
    } catch (error) {
      console.error("Error in getMutedUsers method:", error)
      throw error
    }
  }


  async unMuteUser({ organizationDid, userDid, unMuteDetails }) {
    try {
      const response = await this.postJSON(`/unmute_user/${organizationDid}/${userDid}`, unMuteDetails)
      return response
    } catch (error) {
      console.error("Error in unmuteUser method:", error)
      throw error
    }
  }
  async muteUser({ organizationDid, userDid, muteDetails }) {
    try {
      const response = await this.postJSON(`/mute_user/${organizationDid}/${userDid}`, muteDetails)
      return response
    } catch (error) {
      console.error("Error in muteUser method:", error)
      throw error
    }
  }

  async createConnections({ friend_did, status, note }) {
    return this.postJSON(`/connections`, { friend_did, status, note })
  }
  async deleteConnections({ friend_did }) {
    return this.postJSON(`/delete_connection`, { friend_did })
  }

  async getConnectionsUser() {
    return this.getJSON(`/connections`)
  }
  async getPeopleConnect({ personDid }) {
    return this.getJSON(`/peopleconnect/${personDid}`)
  }
  async getDirectConnection({ user_did }) {
    return this.getJSON(`/direct_connection/${user_did}`)
  }
  async ignoreConnection({ suggested_did, type }) {
    return this.postJSON(`/seen_connection`, { suggested_did, type })
  }
  async getSuggestedConnections() {
    return this.getJSON(`/suggested_connections`)
  }
  async reportUser({ ReportedUserDid, reason }) {
    return this.postJSON('/report', { ReportedUserDid, reason })
  }
  async blockUser({ BlockedUserDid }) {
    return this.postJSON('/block', { BlockedUserDid })
  }
  async createNote({ connectedUserDid, note }) {
    return this.postJSON('/connection/notes', { connectedUserDid, note })
  }

  async getNote({ connectedUserDid }) {
    return this.getJSON(`/connection/${connectedUserDid}/note`)
  }
  async forwardChatMessage({ chatChannelUids, messageUid, message }) {

    const response = await this.postJSON(`/chat/${messageUid}/forward`, { channelUids: chatChannelUids, message })
    if (!response || !response.chatMessage) {
      console.error("Unexpected response structure from server:", response)
    }
    return response.chatMessage
  }

  async markChatMessageAsRead(messageUid){
    return (await this.postJSON(`/chat/${messageUid}/read`)).chatMessages
  }

  async changeChatChannelAttributes(chatChannelUid, attribute){
    return await this.postJSON(`/channel_attributes`, { chatChannelUid, attribute })
  }

  async getChatChannelsAttributes() {
    const chatChannelsAttributes = await this.getJSON(`/channel_attributes`)
    return chatChannelsAttributes
  }

  async editChatMessage(chatChannelUid, messageUid, message, urlPreviewId) {
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/edit`, { message, urlPreviewId })).chatMessage
  }

  async deleteChatMessage(
    chatChannelUid,
    messageUid,
    deletionReason,
    AdminComment
  ) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/delete`, {
        deletionReason,
        AdminComment,
      })
    ).chatMessage
  }


  async undoDeleteChatMessage(chatChannelUid, messageUid, action) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/undo-delete?action=${action}`)).chatMessage
  }


  async createChatMessageReaction(chatChannelUid, messageUid, reaction) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reactions`, { reaction })
    ).reaction
  }

  async removeChatMessageReaction(chatChannelUid, messageUid, reaction) {
    return (
      await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/reactions/remove`, { reaction })
    ).reactionRemovalResult
  }


  async getOrganizationNetworkMemberships(organizationApikey) {
    const { organizationNetworkMemberships } =
      await this.getJSON(`/organizations/${organizationApikey}/network/memberships`)
    return organizationNetworkMemberships
  }

  async createOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey) {
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships`,
      { memberOrganizationApikey },
    )
    return organizationNetworkMembership
  }

  async updateOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey, changes) {
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}`,
      changes,
    )
    return organizationNetworkMembership
  }

  async removeOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey) {
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}/remove`,
    )
    return organizationNetworkMembership
  }

  async sortOrganizationNetworkMemberships(organizationApikey, sortOrder) {
    const { organizationNetworkMemberships } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/sort`,
      { sortOrder },
    )
    return organizationNetworkMemberships
  }

  async getOrganizationTopics(organizationApikey) {
    return (await this.getJSON(`/organizations/${organizationApikey}/topics`)).organizationTopics
  }

  async getSubscriptionsTopicsDetails({
    organizationApikey,
    topicUid,
  }) {
    const {
      topicsSubscribedToMyTopic,
      myTopicsSubscriptions
    } = (await this.getJSON(`/organizations/${organizationApikey}/topics/${topicUid}/subscriptions`))
    return { topicsSubscribedToMyTopic, myTopicsSubscriptions }

  }

  async createOrganizationTopic(organizationApikey, topic) {
    return (await this.postJSON(`/organizations/${organizationApikey}/topics`, { topic })).organizationTopic
  }

  async updateOrganizationTopic(organizationApikey, topicUid, changes) {
    return (
      await this.postJSON(`/organizations/${organizationApikey}/topics/${topicUid}/update`, { changes })
    ).organizationTopic
  }

  async transferTopicFeedPosts(organizationApikey, topicUid, newTopicUid) {
    return (
      await this.postJSON(`/organizations/${organizationApikey}/topics/${topicUid}/transfer_posts`, { newTopicUid })
    ).organizationTopic
  }

  async deleteOrganizationTopic(organizationApikey, topicUid) {
    return (await this.postJSON(`/organizations/${organizationApikey}/topics/${topicUid}/delete`)).organizationTopic
  }

  async createTopicsCategory(organizationApikey, category) {
    return (await this.postJSON(`/organizations/${organizationApikey}/topics_categories`, { category })).topicsCategory
  }

  async followOrganizationTopic(organizationApikey, topicUid){
    return (await this.postJSON(`/organizations/${organizationApikey}/topics/${topicUid}/follow`)).organizationTopic
  }

  async followAllOrganizationTopics(organizationApikey, topicUids){
    return (await this.postJSON(
      `/organizations/${organizationApikey}/topics/follow_all`, { topicUids })
    ).organizationTopics
  }

  async unFollowOrganizationTopic(organizationApikey, topicUid){
    return (await this.postJSON(`/organizations/${organizationApikey}/topics/${topicUid}/unfollow`)).organizationTopic
  }

  async unFollowAllOrganizationTopics(organizationApikey, topicUids){
    return (await this.postJSON(
      `/organizations/${organizationApikey}/topics/unfollow_all`, { topicUids })
    ).organizationTopics
  }

  async verifyUserFollowsOrganizationTopic(organizationApikey, topicUid){
    const { followers } = await this.getJSON(`/organizations/${organizationApikey}/topics/${topicUid}/followers`)
    return followers
  }

  async verifyUserFollowsAllOrganizationTopics(organizationApikey){
    const { followers } = await this.getJSON(`/organizations/${organizationApikey}/topics/followers`)
    return followers
  }

  async getChatChannelsForUser(){
    const chat_channels = await this.getJSON(`/personal-chats`)
    return chat_channels
  }

  async starChatMessage(chatChannelUid, messageUid) {
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/star`, { chatChannelUid, messageUid }))
  }

  async unstarChatMessage(chatChannelUid, messageUid) {
    return (await this.postJSON(`/chat/${chatChannelUid}/${messageUid}/unstar`, { chatChannelUid, messageUid }))
  }

  async getStarredChatMessages(chatChannelUid) {
    const result = await this.getJSON(`/chat/${chatChannelUid}/starred`)
    return result?.starredChatMessages
  }

  async searchInChat(chatChannelUid, keyword) {
    const { result } = await this.getJSON(`/chat/${chatChannelUid}/chat_searching`, { keyword })
    return result
  }

  async hideOrganizationMembership({ organizationApikey, memberUserDid }) {
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/${memberUserDid}/hide`)
  }

  async unhideOrganizationMembership({ organizationApikey, memberUserDid }) {
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/${memberUserDid}/unhide`)
  }

  async updateMembershipDisplayOrder({ organizationApikey, displayOrder, memberUserDid }) {
    return await this.postJSON(`/organizations/${organizationApikey}/memberships/display_order/update`,
      { displayOrder, memberUserDid })
  }

  async updateOrganizationTopicOrder({ organizationApikey, changes }) {
    return (
      await this.postJSON(`/organizations/${organizationApikey}/topics/order`, { organizationApikey, changes })
    ).organizationTopics
  }

  async getChatChannelsForPublicProfile(organizationApikey, publicProfileDid) {
    return (await this.getJSON(`/chat_channels/${organizationApikey}/${publicProfileDid}`)).chatChannels
  }
}

export default new AliceAPI({ urlPrefix: JLINC_A_API })
