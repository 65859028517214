import { h } from 'preact'

// import { useLocalStorage } from 'lib/storageHooks'
// import { publicProfileToDisplayName } from 'lib/publicProfiles'
// import { useFeed } from 'lib/feedPostHooks'

// import OrganizationFeedPosts from 'components/OrganizationFeedPosts'
// import FeedPostForm from 'components/FeedPostForm'
import './index.sass'

export default function UserProfileFeedPage({
  // publicProfile, username, isMe
}){
  // const displayName = publicProfileToDisplayName(publicProfile)
  // const feed = `user:${username}`
  return <div className="UserProfileFeedPage">
    {/* {isMe && <UserProfileFeedPostCreateForm {...{ publicProfile, username, feed }}/>}
    <OrganizationFeedPosts {...{
      feed,
      usersFeed: true,
      emptyMessage: `${displayName} has not posted anything yet.`,
      fullyLoadedMessage: `That's all of ${displayName}'s posts :)`,
    }} /> */}
  </div>
}

UserProfileFeedPage.pageTitle = 'feed'

// function UserProfileFeedPostCreateForm({ publicProfile, feed }) {
//   const { refresh } = useFeed({feed}, 'UserProfileFeedPage')

//   const [post, setPost] = useLocalStorage(
//     `UserProfileFeedPostCreateForm-${publicProfile.did}`,
//     {
//       feedUserDid: publicProfile.did,
//       visibleTo: 2,
//     }
//   )

//   return <FeedPostForm {...{
//     value: post,
//     onChange: setPost,
//     onSuccess: refresh,
//     minMaxVisibleTo: 2,
//     maxMaxVisibleTo: 2,
//   }}/>
// }
