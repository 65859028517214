import { h } from 'preact'
import { useMemo, useCallback } from 'preact/hooks'

import { setPageTitle } from 'lib/Page'
import { useMyPublicProfileDid } from 'resources/auth'
import {
  usePublicProfileByDid,
  usePublicProfileByUsername,
} from 'lib/membershipAppStateHooks'

import RedirectPage from '../RedirectPage'
import NotFoundPage from '../NotFoundPage'
import Unauthorized from 'components/Unauthorized'
import Loading from 'components/Loading'
import TwoColumns from 'components/TwoColumns'
import BannerNav from 'components/BannerNav'
import EndUserAvatar from 'components/EndUserAvatar'
import EditButton from 'components/EditButton'
import PublicProfileMembershipsList from 'components/PublicProfileMembershipsList'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import EndUserBanner from 'components/EndUserBanner'
import Link from 'components/Link'
import EditableEndUserBanner from 'components/EditableEndUserBanner'

import router from './router'
import './index.sass'

export default function UserProfilePage(props){
  const { location } = props
  const username = decodeURIComponent(props.params.username)
  if (!username) return <NotFoundPage {...props}/>
  if (username.startsWith('did:'))
    return <LoadPublicProfileByDid {...{did: username, location }}/>
  return <Content {...{...props, username}}/>
}

const loading = <Loading className="UserProfilePage" type="fullPage"/>

function LoadPublicProfileByDid({ did, location }){
  const {
    loadingPublicProfile,
    publicProfileNotfound,
    publicProfile,
  } = usePublicProfileByDid(did, 'UserProfilePage')
  if (loadingPublicProfile) return loading
  if (publicProfile) return <RedirectPage to={{
    ...location,
    pathname: location.pathname.replace(did, publicProfile.username),
  }}/>
  if (publicProfileNotfound) return <NotFoundPage />
  return <div>DID DETECTED LOADING did={did}</div>
}

function Content({username, currentUser, ...props}){
  const setUserProfilePageTitle = useCallback(
    pageTitle => setPageTitle((pageTitle ? `${pageTitle} | ` : '') + `@${username}`),
    [username],
  )
  setUserProfilePageTitle(``)
  const myPublicProfileDid = useMyPublicProfileDid()
  const {
    loadingPublicProfile,
    publicProfileNotfound,
    publicProfile,
  } = usePublicProfileByUsername(username, 'UserProfilePage')

  if (loadingPublicProfile) return loading

  const route = router.resolve(`/${props.params.path || ''}`)

  if (!route || publicProfileNotfound)
    return <NotFoundPage {...props}/>

  const { Component, params } = route

  if (Component.pageTitle) setUserProfilePageTitle(Component.pageTitle)

  const isMe = myPublicProfileDid === publicProfile.did

  const RightColumn = Component.rightColumn || PublicProfileMembershipsList
  const loggedIn = !!currentUser

  return <div className="UserProfilePage">
    <Nav {...{ isMe, publicProfile, loggedIn }}/>
    {loggedIn
      ? <TwoColumns>
        <div>
          <Component {...{
            setPageTitle: setUserProfilePageTitle,
            publicProfile,
            username,
            isMe,
            params: {...props.params, ...params},
          }}/>
        </div>
        <RightColumn {...{ publicProfile }}/>
      </TwoColumns>
      : <Unauthorized/>
    }
  </div>
}

function Nav({ isMe, publicProfile, loggedIn }){

  const pathnamePrefix = `/@${publicProfile.username}`
  const editPagePath = `${pathnamePrefix}/edit`

  const navButtons = useMemo(
    () => {
      const navButtons = [
        {
          value: 'About',
          icon: 'home',
          href: `${pathnamePrefix}/about`,
          disabled: !loggedIn,
        },
        {
          value: 'Memberships',
          icon: 'people',
          href: `${pathnamePrefix}/memberships`,
          className: 'hide-above-tablet',
          disabled: !loggedIn,
        },
      ]
      // if (!isDataYogi) navButtons.push(
      //   {
      //     value: 'Personal Posts',
      //     icon: 'channel',
      //     href: `${pathnamePrefix}`,
      //     selectedIfExact: true,
      //     disabled: !loggedIn,
      //   }
      // )
      return navButtons
    },
    [isMe, publicProfile.username, publicProfile.did],
  )

  const extraButtons = useMemo(
    () => {
      if (isMe) return [
        <EditButton
          href={editPagePath}
          size="sm"
          title="Edit Profile"
        />
      ]
    },
    [isMe, pathnamePrefix, editPagePath],
  )

  const banner = (isMe
    ? <EditableEndUserBanner />
    : <EndUserBanner publicProfile={publicProfile} />
  )

  const name = <div
    className="flex-col flex-align-center UserProfilePage-navName"
  >
    <LinkToPublicProfile {...{publicProfile}} type="text"/>
    <LinkToPublicProfile {...{publicProfile}} username/>
  </div>

  const icon = isMe
    ? <Link href={editPagePath}><EndUserAvatar {...{ publicProfile, size: 'md' }} /></Link>
    : <LinkToPublicProfile {...{
      publicProfile,
      className: 'UserProfilePage-avatar',
      type: 'none',
    }}>
      <EndUserAvatar {...{ publicProfile, size: 'md' }} />
    </LinkToPublicProfile>

  return <BannerNav {...{
    banner,
    name,
    icon,
    navButtons,
    extraButtons,
  }}/>
}

