import { h } from 'preact'
import './index.sass'
import { useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { createClassComponent } from 'lib/preactHelpers'
import { generateLinkToMap } from 'lib/mapHelpers'
import { toExternalUrl } from 'lib/urlHelpers'

import Form from 'components/Form'
import Link from 'components/Link'
import WYSIWYGContent from 'components/WYSIWYGContent'

const EntityInfo = createClassComponent('EntityInfo')
export default EntityInfo
export const Label = createClassComponent('EntityInfo-Label', Form.Label)
export const Value = createClassComponent('EntityInfo-Value', 'div')

EntityInfo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export function Detail({ className, label, value, optional = true }){
  if (optional && !value) return

  const isRichText = /<\/?[a-z][\s\S]*>/i.test(value)

  return <div className={classNames('EntityInfo-Detail', { className })}>
    {label && <Label>{`${label}`.toUpperCase()}:</Label>}
    <Value>{isRichText ? <WYSIWYGContent source={value} /> : value}</Value>
  </div>
}

export function Address({
  optional = true,
  label = 'LOCATION',
  address,
  city,
  province,
  state = province,
  country,
  post_code,
  postalCode = post_code,
  minimal,
}){
  const [mapsUrl, humanizedAddress] = useMemo(
    () => [
      generateLinkToMap(address, city, state, postalCode, country),
      humanizeAddress({address, city, state, postalCode, country, minimal}),
    ],
    [address, city, state, postalCode, country],
  )
  if (optional && !humanizedAddress) return
  return <Detail
    label={minimal ? undefined : label}
    value={<Link href={mapsUrl} newWindow>{humanizedAddress}</Link>}
  />
}

function humanizeAddress({address, city, state, postalCode, country, minimal}){
  const line = (...parts) => {
    parts = parts.filter(p => p && p.trim())
    return parts.length === 0 ? '' : parts.join(', ') + '\n'
  }
  let humanizedAddress = ''
  if (!minimal) humanizedAddress += line(address)
  humanizedAddress += line(city, state)
  if (minimal) humanizedAddress += line(country)
  else humanizedAddress += line(country, postalCode)

  return humanizedAddress
}

export function Url({ optional = true, value, ...props }){
  const href = value && toExternalUrl(value)
  if (optional && !href) return
  return <Link {...props} href={href} newWindow>{href}</Link>
}
