import { Fragment, h } from "preact"
import { useAppState } from "lib/appState"
import { useCallback, useEffect, useMemo, useRef, useState } from "preact/hooks"
import IconButton from "components/IconButton"
import { FILE_ATTACHMENT_PREFIX } from "../../a-portal/pages/OrganizationChatPage/FILE_ATTACHMENT_PREFIX"
import WYSIWYGContent from "components/WYSIWYGContent"
import PlainText from "components/PlainText"
import { useMyOrganizationMembership, useMyPublicProfile, usePublicProfiles } from "lib/membershipAppStateHooks"
import FileAttachment from "components/FileAttatchement"
import Timestamp from "components/Timestamp"
import Subtext from "components/Subtext"
import EndUserAvatar from "components/EndUserAvatar"
import Link from "components/Link"
import ChatUrlPreview from "components/ChatUrlPreview"
import { publicProfileToPathname } from "lib/publicProfiles"
import { useChatChannel } from "lib/chatHooks"

import { ChatMessages } from "../../a-portal/pages/OrganizationChatPage/ChatMessages"
import { ChatMessageForm } from "../../a-portal/pages/OrganizationChatPage/ChatMessageForm"
import isMobile from "lib/isMobile"
import "./index.sass"
import NotAMemberMessage from "components/NotAMemberMessage"

export default function ThreadRepliesModal({
  close,
  chatChannelUid,
  organization,
  replyingTo,
  setReplyingTo,
  isEditing,
  setIsEditing,
  isEditingThread,
  setIsEditingThread,
  editedMessage,
  setEditedMessage,
  editMessageUid,
  setEditMessageUid,
}){

  const { isAdmin, isCurator } = useMyOrganizationMembership(
    organization?.apikey,
    "OrganizationChatPage"
  )

  const {
    myPublicProfile,
  } = useMyPublicProfile('ThreadRepliesModal')

  const { allMessages, openedThread } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:messages`]: 'allMessages',
      [`chat:channel:${chatChannelUid}:thread:open`]: 'openedThread'
    },
    'ThreadRepliesModal'
  )

  const chatThread = allMessages?.find(m => m.uid === openedThread) || []

  const {
    message, createdAt, creatorUserDid, uid: threadUid, replies, editedMessage: editedThreadMessage, replyCount
  } = chatThread


  const fileAttachment = (
    message &&
    message.startsWith(FILE_ATTACHMENT_PREFIX) &&
    JSON.parse(message.split(FILE_ATTACHMENT_PREFIX)[1])
  )

  const { publicProfiles } = usePublicProfiles(
    [creatorUserDid],
    'ThreadRepliesModal'
  )

  const isDeleted = chatThread?.deletedAt
  const deletedByRole = chatThread?.deletedByRole
  const fromMe = myPublicProfile?.did === creatorUserDid
  const deletedByUserDid = chatThread?.deletedByUserDid
  const publicProfile = publicProfiles[0]
  const timestamp = createdAt
  const urlPreview = chatThread?.urlPreview

  const messageIsWYSIWYG = /^<(h\d|p|ul|figure)/.test(message)

  const {
    pendingChatMessages,
    markChatMessagesAsRead,
    chatChannel,
    deleteChatMessage,
    undoDeleteChatMessage,
    editChatMessage,
    replyToMessage,
    createChatMessage,
    starChatMessage,
    unstarChatMessage,
    leaveChannel,
    deleteChatChannel,
    createChatMessageReaction,
    removeChatMessageReaction,
  } = useChatChannel(chatChannelUid, threadUid, 'ThreadRepliesModal')

  const addReactionToMessage = async (messageUid, emoji) => {
    try {
      await createChatMessageReaction(chatChannelUid, messageUid, emoji)
    } catch (error) {
      console.error(`Failed to add reaction to message ${messageUid}`, error)
    }
  }

  const handleRemoveReaction = async (messageUid, emoji) => {
    try {
      await removeChatMessageReaction(chatChannelUid, messageUid, emoji)

    } catch (error) {
      console.error(`Failed to remove reaction from message ${messageUid}`, error)
    }
  }


  const [isMessageDeletedByAdmin, setIsMessageDeletedByAdmin] = useState(false)

  const isThread = true

  const chatChannelType = chatChannel?.type

  const { starredChatMessages } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:starredMessages`] : 'starredChatMessages'
    },
    'ThreadRepliesModal'
  )

  const [starredMessagesIds, setStarredMessagesIds] = useState(new Set())

  useEffect(() => {
    setStarredMessagesIds(new Set(starredChatMessages?.map(({ uid }) => uid) || []))
  }, [starredChatMessages, chatChannelUid])

  const handleStarMessage = useCallback((uid) => {
    const newIds = new Set([...starredMessagesIds, uid])
    setStarredMessagesIds(newIds)
    starChatMessage(chatChannelUid, uid)
  }, [starredMessagesIds, starChatMessage, chatChannelUid])

  const handleUnStarMessage = useCallback((uid) => {
    const newIds = new Set([...starredMessagesIds])
    newIds.delete(uid)
    setStarredMessagesIds(newIds)
    unstarChatMessage(chatChannelUid, uid)
  }, [starredMessagesIds, unstarChatMessage, chatChannelUid])

  const isOneToOne = chatChannel?.memberUserDids?.length === 2

  const chatMessageFormRef = useRef()

  const focusInput = useCallback(
    () => {
      if (isMobile) return
      const start = Date.now()
      const stop = () => clearInterval(intervalId)
      const focus = () => {
        if (Date.now() - start > 1000) return stop()
        if (!chatMessageFormRef.current) return
        const input = chatMessageFormRef.current.base.querySelector('textarea')
        if (!input) return
        const { activeElement } = global.document
        if (activeElement === input) return stop()
        input.focus()
      }
      const intervalId = setInterval(focus, 100)
      return () => clearInterval(intervalId) // cleanup function
    },
    [chatMessageFormRef, isMobile]
  )

  const handleDeleteMessage = useCallback(
    async (messageUid, deletionReason = "", AdminComment = "") => {
      try {
        await deleteChatMessage(chatChannelUid, messageUid, deletionReason, AdminComment)
      } catch (error) {
        console.error('Error deleting chat message:', error)
      }
    },
    [deleteChatMessage, chatChannelUid]
  )

  const handleUndoDeleteClick = useCallback(async (chatChannelUid, messageUid, action) => {
    try {
      await undoDeleteChatMessage(chatChannelUid, messageUid, action)
    } catch (error) {
      console.error(`Error processing undelete request with UID: ${messageUid}`, error)
    }
  }, [undoDeleteChatMessage, chatChannelUid])

  const [inputStr, setInputStr] = useState('')


  const handleEditMessage = useCallback(
    (uid, message, isDeletedByAdmin, editedMessage) => {
      setIsEditingThread(true)
      setIsEditing(false)
      setEditedMessage(editedMessage || message)
      setEditMessageUid(uid)
      setIsMessageDeletedByAdmin(isDeletedByAdmin)
    },
    []
  )


  const handleCancelEdit = useCallback(() => {
    setIsEditingThread(false)
    setIsEditing(false)
    setEditedMessage('')
    setEditMessageUid(null)
  }, [])

  const uploadFiles = useCallback(
    files => {
      files.forEach(file => {
        createChatMessage({ file })
      })
      focusInput()
    },
    [createChatMessage]
  )

  const currentUserDid = myPublicProfile.did

  const hasLeftChannel = useMemo(() => {
    const leftEvent = chatChannel.events?.slice().reverse().find(event =>
      event.eventType === 'left' && event.actorUserDid === currentUserDid
    )
    if (!leftEvent) return false

    const rejoinedEvent = chatChannel.events.find(event =>
      event.createdAt > leftEvent.createdAt &&
      (event.eventType === 'join' && event.actorUserDid === currentUserDid)
    )

    return !rejoinedEvent
  }, [chatChannel.events, currentUserDid])

  const handleInput = (currentText) => {
    console.log('handleInput called', currentText)
    setInputStr(currentText)
  }

  let modal

  modal = (
    <Fragment>
      <div className="ThreadRepliesModal-header">
        <span className={"ThreadRepliesModal-header-title"}>
        Reply Thread
        </span>
        <IconButton
          type="closeX"
          onClick={close}
          className={"ThreadRepliesModal-header-closeX"}
        />
      </div>
      <div className="ThreadRepliesModal-body">
        <div className="ThreadRepliesModal-thread-messages">
          {chatThread && (
            <ThreadMessage
              {...{
                message,
                editedThreadMessage,
                publicProfiles,
                timestamp,
                urlPreview,
                threadUid,
                createdAt,
                creatorUserDid,
                publicProfile,
                fileAttachment,
                isDeleted,
                deletedByRole,
                fromMe,
                deletedByUserDid,
                messageIsWYSIWYG,
                chatChannelUid,
                replyCount
              }}
            />
          )}
          <div className="ThreadRepliesModal-replies">
            <ChatMessages {...{
              chatMessages : replies,
              pendingChatMessages,
              myPublicProfile,
              focusInput,
              markChatMessagesAsRead,
              createdAt,
              creatorUserDid,
              chatChannelUid,
              editChatMessage,
              replyToMessage,
              handleDeleteMessage,
              handleUndoDeleteClick,
              replyingTo,
              setReplyingTo,
              handleEditMessage,
              starChatMessage,
              unstarChatMessage,
              handleStarMessage,
              handleUnStarMessage,
              starredMessagesIds,
              isOneToOne,
              isAdmin,
              isCurator,
              organization,
              chatChannelType,
              isThread,
              replies,
              threadUid,
              addReactionToMessage,
              handleRemoveReaction,
              deleteChatChannel,
              leaveChannel,
              chatChannel,
              memberUserDids: chatChannel?.memberUserDids,
              handleInput
            }} />
          </div>
        </div>
        <div className="ThreadRepliesModal-footer">
          {isEditingThread && !isEditing && !hasLeftChannel ? (
            <ChatMessageForm
              isEditing={isEditingThread}
              editedMessage={editedMessage}
              handleEditMessage={setEditedMessage}
              handleCancelEdit={handleCancelEdit}
              isDeletedByAdmin={isMessageDeletedByAdmin}
              closeStarredMessagesModal={close}
              editChatMessage={editChatMessage}
              setIsEditing={setIsEditing}
              setIsEditingThread={setIsEditingThread}
              setEditedMessage={setEditedMessage}
              setEditMessageUid={setEditMessageUid}
              editMessageUid={editMessageUid}
              chatChannelUid={chatChannelUid}
              inputStr={inputStr}
              setInputStr={setInputStr}
              handleInput={handleInput}
            />
          ) : !hasLeftChannel ? (
            <ChatMessageForm
              {...{
                ref: chatMessageFormRef,
                uid: threadUid,
                createChatMessage,
                uploadFiles,
                disabled: !!chatThread.deletedAt || hasLeftChannel,
                replyingTo : {
                  message: message,
                  creatorUserDid: creatorUserDid,
                  uid: threadUid,
                },
                hasLeftChannel,
                setReplyingTo,
                replyToMessage,
                chatChannelUid,
                isEditingThread,
                setIsEditingThread,
                isThread,
                inputStr,
                setInputStr,
                handleInput
              }} />
          ) : (
            <NotAMemberMessage />
          )}
        </div>
      </div>
    </Fragment>
  )

  return <>
    {modal}
  </>
}

function ThreadMessage({
  replyCount,
  message,
  editedThreadMessage,
  createdAt,
  publicProfile,
  fileAttachment,
  isDeleted,
  deletedByRole,
  fromMe,
  deletedByUserDid,
  messageIsWYSIWYG,
  threadUid : uid,
  publicProfiles,
  timestamp,
  urlPreview
}) {
  const messageContent = editedThreadMessage || message
  const isEdited = !!editedThreadMessage


  return <div className={'ThreadMessage-container'}>
    <div
      className={`ThreadMessage-Bubble-details${fromMe ? '-fromMe' : ''}`}>
      <Subtext className={'ThreadMessage-Bubble-details-displayName'}>
        {publicProfile?.displayName || publicProfile?.username}
      </Subtext>
      <Subtext className={'ThreadMessage-Bubble-details-time'}>
        <Timestamp time={createdAt} format="time" />
      </Subtext>
    </div>
    <div className={"ThreadMessage-body"} id={`message-${uid}`}>
      <Link href={publicProfile && publicProfileToPathname(publicProfile)}>
        <EndUserAvatar
          {...{
            publicProfile,
            size: "sm",
          }}
        />
      </Link>
      {fileAttachment ? (
        <div className="ThreadMessage-Bubble">
          <FileAttachment {...fileAttachment} />
        </div>
      ) : (
        <div className={`ThreadMessage-Bubble ${fromMe ? 'fromMe' : 'from-others'}`} title={timestamp}>
          {urlPreview && (
            <div className="RichMediaInput-urlPreview ">
              <ChatUrlPreview {...{
                ...urlPreview,
                canClosed: false,
                sendUrlPreview: true,
              }} />
            </div>
          )}
          {isDeleted ? (
            <div
              className="deleted-message-placeholder"
              style={{
                fontStyle: "italic",
                fontSize: "14px",
              }}
            >
              Message was deleted by
              {deletedByRole === "admin" || deletedByRole === "curator"
                ? ` the ${deletedByRole}`
                : fromMe
                  ? " you"
                  : ` ${publicProfiles.find((p) => p.did === deletedByUserDid)?.username || " unknown"}`}
            </div>
          ) : messageIsWYSIWYG ? (
            <Fragment>
              <WYSIWYGContent source={messageContent} />
            </Fragment>
          ) : (
            <PlainText text={messageContent} />
          )}
          {isEdited && <span className="edited-label">edited</span>}
        </div>
      )}
    </div>
    {replyCount && (
      <span className={"ThreadMessage-replies-number"}>
        {replyCount} {replyCount === 1 ? "reply" : "replies"}
      </span>
    )}
  </div>
}
