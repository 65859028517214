import {h, Fragment} from 'preact'
import { useEffect, useRef, useState } from 'preact/compat'
import { useChangeChatChannelAttributes, useChatChannel, useChatChannelMembershipDetails } from 'lib/chatHooks'
import Icon from 'components/Icon'
import './index.sass'
import { useAppState } from 'lib/appState'


export function ChatOptionsMenu({
  myPublicProfile,
  deleteChatChannel,
  leaveChannel,
  synopsis,
  isChatWindow,
  openStarredMessagesModal,
  closeChatSearchModal,
  closeThreadRepliesModal,
  handleOpenGroupEditModal,
  setShowManageChatMembersModal,
  chatChannelDetails,
  handleOpenAssignAdminModal,
  chatChannel,
}) {

  const [showDropdown, setShowDropdown] = useState(false)
  const [membershipDetails, setMembershipDetails] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isOnlyOneAdmin, setIsOnlyOneAdmin] = useState(false)

  const dropdownRef = useRef(null)
  const chatChannelUid = chatChannelDetails.uid

  const selectedChat = synopsis?.find(chat => chat.uid === chatChannelUid)

  let isUnread = selectedChat?.is_unread || selectedChat?.unread  > 0
  let isNotificationsMuted = selectedChat?.is_notifications_muted

  const {
    loadingChatChannelMembershipDetails,
    errorLoadingChatChannelMembershipDetails,
    loadChatChannelMembershipDetails
  } = useChatChannelMembershipDetails(chatChannelUid, "OrganizationChatPage")

  const {
    markAllChatMessagesAsRead,
  } = useChatChannel(selectedChat?.uid, 'OrganizationChatPage')

  const {
    changeChatChannelAttributes,
  } = useChangeChatChannelAttributes('OrganizationChatPage')

  function markAsUnread() {
    changeChatChannelAttributes(selectedChat.uid, { is_unread: true })
  }

  function markAsRead() {
    changeChatChannelAttributes(selectedChat.uid, { is_unread: false })
    if (selectedChat?.unread > 0) {
      markAllChatMessagesAsRead()
    }
  }

  function muteChatNotifications() {
    changeChatChannelAttributes(selectedChat.uid, { is_notifications_muted: true })
  }

  function unmuteChatNotifications() {
    changeChatChannelAttributes(selectedChat.uid, { is_notifications_muted: false })
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const { takeAction } = useAppState({}, 'OrganizationChatPage')

  useEffect(() => {
    if (chatChannelUid) {
      loadChatChannelMembershipDetails(chatChannelUid)
        .then((details) => {
          setMembershipDetails(details)
          const userIsAdmin = details.memberships.some(
            (member) =>
              member.memberUserDid === myPublicProfile?.did &&
              member.isAdmin &&
              !member.leftAt
          )
          setIsAdmin(
            chatChannelDetails?.creatorUserDid === myPublicProfile?.did ||
              userIsAdmin
          )
          setIsOnlyOneAdmin(
            details.memberships.filter(
              (member) => member.isAdmin && !member.leftAt
            ).length === 1
          )
        })
        .catch((error) => {
          console.error("Error loading membership details:", error)
        })
    }
  }, [
    chatChannelUid,
    loadChatChannelMembershipDetails,
    myPublicProfile?.did,
    chatChannelDetails?.creatorUserDid,
  ])

  const handleDeleteChannel = async () => {
    // eslint-disable-next-line no-alert
    const isConfirmed = confirm("Are you sure you want to delete this chat channel? This action cannot be undone.")

    if (isConfirmed) {
      try {
        await deleteChatChannel(chatChannelUid)
        // eslint-disable-next-line no-alert
        alert("This chat channel has been successfully deleted.")
      } catch (error) {
        console.error("Failed to delete chat channel:", error)
        // eslint-disable-next-line no-alert
        alert("Failed to delete chat channel. Please try again.")
      }
    }
  }

  const handleLeaveChannel = async () => {
    if (isAdmin && isOnlyOneAdmin) {
      handleOpenAssignAdminModal()
    } else {
      // eslint-disable-next-line no-alert
      if(!confirm("Are you sure you want to leave this chat channel?")) return

      try {
        await leaveChannel(chatChannelUid)
      } catch (error) {
        console.error("Error leaving channel:", error)
      }
    }
  }


  const handleManageMembers = async () => {
    if (isAdmin) {
      setShowManageChatMembersModal(true)

    }
    setShowManageChatMembersModal(true)
  }

  const hasLeftChannel = membershipDetails?.memberships.some(
    (membership) =>
      membership.memberUserDid === myPublicProfile?.did && (membership.leftAt || membership.deletedAt)
  )

  const canDelete = (() => {
    if (chatChannelDetails?.type === 'general') {
      return isAdmin
    }
    if (chatChannelDetails?.type === 'dm') {
      const isMember = membershipDetails?.memberships?.some(
        membership => membership.memberUserDid === myPublicProfile?.did
      )
      const isDeleted = membershipDetails?.deletedAt != null
      return isMember && !isDeleted
    }
    return false
  })()

  const generateMenuItems = () => {
    const menuItems = [

      { label: 'Mute Notifications',
        onClick: muteChatNotifications,
        condition: !hasLeftChannel && !isNotificationsMuted, icon: 'mute'
      },
      {
        label: 'Unmute Notifications',
        onClick: unmuteChatNotifications,
        condition: !hasLeftChannel && isNotificationsMuted, icon: 'unmute'
      },
      { label: 'Mark as read', onClick: markAsRead, condition: !hasLeftChannel && isUnread, icon: 'markAsRead'},
      { label: 'Mark as unread', onClick: markAsUnread, condition: !hasLeftChannel && !isUnread, icon: 'markAsUnread'},
      { label: 'View Starred Messages', onClick: () => {
        takeAction('chat.loadStarredMessages', chatChannel)
        closeThreadRepliesModal()
        closeChatSearchModal()
        openStarredMessagesModal()
      }, condition: isChatWindow, icon: 'starred'},
      { label: 'Report / Block', condition: false, icon: 'flag'},
      { label: 'Edit Chat Info',
        onClick: handleOpenGroupEditModal,
        condition: isAdmin && !hasLeftChannel,
        icon: 'edit'
      },
      { label: 'Manage Members', onClick: handleManageMembers, condition: isAdmin && !hasLeftChannel, icon: 'people' },
      { label: 'Leave Chat', onClick: handleLeaveChannel, condition: !hasLeftChannel, icon: 'close' },
      { label: 'Delete Chat',
        onClick: handleDeleteChannel,
        condition: canDelete,
        icon: 'delete'
      },
    ]

    return menuItems.filter(item => item.condition)
  }

  if (loadingChatChannelMembershipDetails) {
    return <div>...</div>
  }

  if (errorLoadingChatChannelMembershipDetails) {
    return (
      <div>
        Error loading membership details:{" "}
        {errorLoadingChatChannelMembershipDetails}
      </div>
    )
  }

  return (
    <Fragment>
      <div className={'ChatChannelListMember-dropdown'} onClick={toggleDropdown} ref={dropdownRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-menu-vertical-dots"
        >
          <circle cx="12" cy="12" r="1" />
          <circle cx="12" cy="5" r="1" />
          <circle cx="12" cy="19" r="1" />
        </svg>

        {showDropdown && (
          <ul className="ChatChannelListMember-dropdown-menu">
            {generateMenuItems().map((item, index) => (
              <li key={index} onClick={item.onClick} className="ChatChannelListMember-dropdown-menu-item">
                <Icon type={item.icon} size="lg" />
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Fragment>
  )
}
